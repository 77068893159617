//  -yzm

//other
let __system = {
    __activeid: "",
    __menu: [{
        pageFlag: false,
        id: "quote",
        title: "报价管理",
        icon: "el-icon-menu",
        visible:true,
        children: [
            {
                pageFlag: false,
                id: "quotebase",
                title: "基础资料",
                icon: "el-icon-menu",
                visible:false,
                children: [
                    {
                        pageFlag: true,
                        id: "ax_sys_partneruser",
                        title: "用户登录表",
                        name: "ax_sys_partneruser",
                        icon: "el-icon-document",
                        component: "views/chanjet/AxSysPartneruserList.vue",
                        componentdetail: "views/chanjet/AxSysPartneruserDetail.vue",
                        visible: false
                    },
                    {
                        pageFlag: true,
                        id: "ax_main_price_list",
                        title: "主料最新单价",
                        name: "ax_main_price_list",
                        icon: "el-icon-document",
                        component: "views/quote/AxMainPriceListList.vue",
                        componentdetail: "views/quote/AxMainPriceListDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_zczpipei",
                        title: "主材料半成品关联",
                        name: "ax_z_zczpipei",
                        icon: "el-icon-document",
                        component: "views/quote/AxZZczpipeiList.vue",
                        componentdetail: "views/quote/AxZZczpipeiDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_workplay",
                        title: "镶法镶工",
                        name: "ax_z_workplay",
                        icon: "el-icon-document",
                        component: "views/quote/AxZWorkplayList.vue",
                        componentdetail: "views/quote/AxZWorkplayDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_cplb",
                        title: "产品系列",
                        name: "ax_z_bas_cplb",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasCplbList.vue",
                        componentdetail: "views/quote/AxZBasCplbDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_huil_list",
                        title: "汇率",
                        name: "ax_z_huil_list",
                        icon: "el-icon-document",
                        component: "views/quote/AxZHuilListList.vue",
                        componentdetail: "views/quote/AxZHuilListDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "electroplatetype",
                        title: "外协",
                        name: "electroplatetype",
                        icon: "el-icon-document",
                        component: "views/quote/ElectroplatetypeList.vue",
                        componentdetail: "views/quote/ElectroplatetypeDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_elkind",
                        title: "电镀类别",
                        name: "ax_z_bas_elkind",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasElkindList.vue",
                        componentdetail: "views/quote/AxZBasElkindDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_color",
                        title: "颜色维护",
                        name: "ax_z_bas_color",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasColorList.vue",
                        componentdetail: "views/quote/AxZBasColorDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_processcost",
                        title: "加工成本设置",
                        name: "ax_z_bas_processcost",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasProcesscostList.vue",
                        componentdetail: "views/quote/AxZBasProcesscostDetail.vue",
                        visible: true
                    },
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_cpkind",
                    //     title: "产品类别简称对照",
                    //     name: "ax_z_cpkind"
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZCpkindList.vue",
                    //     componentdetail: "views/quote/AxZCpkindDetail.vue",
                    //     visible: true
                    // },
                    {
                        pageFlag: true,
                        id: "ax_z_deptlev",
                        title: "部门对应产品等级建立",
                        name: "ax_z_deptlev",
                        icon: "el-icon-document",
                        component: "views/quote/AxZDeptlevList.vue",
                        componentdetail: "views/quote/AxZDeptlevDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_commoditylev",
                        title: "等级档案",
                        name: "ax_z_commoditylev",
                        icon: "el-icon-document",
                        component: "views/quote/AxZCommoditylevList.vue",
                        componentdetail: "views/quote/AxZCommoditylevDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_gxdept",
                        title: "工序部门",
                        name: "ax_z_bas_gxdept",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasGxdeptList.vue",
                        componentdetail: "views/quote/AxZBasGxdeptDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_gxgsdept",
                        title: "工序归属部门",
                        name: "ax_z_bas_gxgsdept",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasGxgsdeptList.vue",
                        componentdetail: "views/quote/AxZBasGxgsdeptDetail.vue",
                        visible: true
                    }, {
                        pageFlag: true,
                        id: "ax_bas_images",
                        title: "图片库",
                        name: "ax_bas_images",
                        icon: "el-icon-document",
                        component: "views/chanjet/AxBasImagesList.vue",
                        componentdetail: "views/chanjet/AxBasImagesDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_userdept",
                        title: "报表权限控制",
                        name: "ax_z_userdept",
                        icon: "el-icon-document",
                        component: "views/zb/permission/AxZUserdeptList.vue",
                        componentdetail: "views/zb/permission/AxZUserdeptDetail.vue",
                        visible: true
                    }
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_deptcode",
                    //     title: "部门对应简称建立",
                    //     name: "ax_z_deptcode",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZDeptcodeList.vue",
                    //     componentdetail: "views/quote/AxZDeptcodeDetail.vue",
                    //     visible: true
                    // },
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_matcode",
                    //     title: "材质对应简称建立",
                    //     name: "ax_z_matcode",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZMatcodeList.vue",
                    //     componentdetail: "views/quote/AxZMatcodeDetail.vue",
                    //     visible: true
                    // }
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_bas_inventoryclass",
                    //     title: "存货分类",
                    //     name: "ax_z_bas_inventoryclass",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZBasInventoryclassList.vue",
                    //     componentdetail: "views/quote/AxZBasInventoryclassDetail.vue",
                    //     visible: false
                    // },
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_bas_inventory",
                    //     title: "存货",
                    //     name: "ax_z_bas_inventory",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZBasInventoryList.vue",
                    //     componentdetail: "views/quote/AxZBasInventoryDetail.vue",
                    //     visible: false
                    // },
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_bas_paytype",
                    //     title: "支付方式",
                    //     name: "ax_z_bas_paytype",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZBasPaytypeList.vue",
                    //     componentdetail: "views/quote/AxZBasPaytypeDetail.vue",
                    //     visible: true
                    // },
                    // {
                    //     pageFlag: true,
                    //     id: "ax_z_bas_texture",
                    //     title: "材质",
                    //     name: "ax_z_bas_texture",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/AxZBasTextureList.vue",
                    //     componentdetail: "views/quote/AxZBasTextureDetail.vue",
                    //     visible: false
                    // },
                   
                    // ,
                    // {
                    //     pageFlag: true,
                    //     id: "vxetabletest",
                    //     title: "vxetabletest",
                    //     name: "vxetabletest",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/vxetabletest.vue",
                    //     componentdetail: "views/quote/vxetabletest.vue",
                    //     visible: true
                    // }
                ]
            },
            {
                pageFlag: false,
                id: "quotepz",
                title: "编码配置",
                icon: "el-icon-menu",
                visible:false,
                children: [
                    {
                        pageFlag: true,
                        id: "ax_z_cpkind",
                        title: "产品类别简称对照",
                        name: "ax_z_cpkind",
                        icon: "el-icon-document",
                        component: "views/quote/AxZCpkindList.vue",
                        componentdetail: "views/quote/AxZCpkindDetail.vue",
                        visible: false
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_deptcode",
                        title: "部门对应简称建立",
                        name: "ax_z_deptcode",
                        icon: "el-icon-document",
                        component: "views/quote/AxZDeptcodeList.vue",
                        componentdetail: "views/quote/AxZDeptcodeDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_matcode",
                        title: "材质对应简称建立",
                        name: "ax_z_matcode",
                        icon: "el-icon-document",
                        component: "views/quote/AxZMatcodeList.vue",
                        componentdetail: "views/quote/AxZMatcodeDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_stylesize",
                        title: "尺寸对照表",
                        name: "ax_z_stylesize",
                        icon: "el-icon-document",
                        component: "views/quote/AxZStylesizeList.vue",
                        componentdetail: "views/quote/AxZStylesizeDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_sbeicode",
                        title: "识别码",
                        name: "ax_z_sbeicode",
                        icon: "el-icon-document",
                        component: "views/quote/AxZSbeicodeList.vue",
                        componentdetail: "views/quote/AxZSbeicodeDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_bomgroup",
                        title: "人员组别",
                        name: "ax_z_bas_bomgroup",
                        icon: "el-icon-document",
                        component: "views/quote/prem/AxZBasBomgroupList.vue",
                        componentdetail: "views/quote/prem/AxZBasBomgroupDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_bas_bomperm",
                        title: "组别分配",
                        name: "ax_z_bas_bomperm",
                        icon: "el-icon-document",
                        component: "views/quote/prem/AxZBasBompermList.vue",
                        componentdetail: "views/quote/prem/AxZBasBompermDetail.vue",
                        visible: true
                    }
                ]
            },
            {
                pageFlag: false,
                id: "rptpz",
                title: "报表配置",
                icon: "el-icon-menu",
                visible:false,
                children: [
                    {
                        pageFlag: true,
                        id: "ax_z_rptkind",
                        title: "类别",
                        name: "ax_z_rptkind",
                        icon: "el-icon-document",
                        component: "views/quote/AxZRptkindList.vue",
                        componentdetail: "views/quote/AxZRptkindDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_rptdetail",
                        title: "类别工序匹配",
                        name: "ax_z_rptdetail",
                        icon: "el-icon-document",
                        component: "views/quote/AxZRptdetailList.vue",
                        componentdetail: "views/quote/AxZRptdetailDetail.vue",
                        visible: true
                    }
                ]
            },       
            {
                pageFlag: false,
                id: "syccommon",
                title: "日常业务",
                icon: "el-icon-menu",
                visible:true,
                children: [
                    {
                        pageFlag: true,
                        id: "ax_z_bas_boma",
                        title: "bom",
                        name: "ax_z_bas_boma",
                        icon: "el-icon-document",
                        component: "views/quote/AxZBasBomaList.vue",
                        componentdetail: "views/quote/AxZBasBomaDetail.vue",
                        visible: false
                    },

                    {
                        pageFlag: true,
                        id: "ax_z_pus_purdm",
                        title: "订单导入",
                        name: "ax_z_pus_purdm",
                        icon: "el-icon-document",
                        component: "views/quote/AxZPusPurdmList.vue",
                        componentdetail: "views/quote/AxZPusPurdmDetail.vue",
                        visible: false
                    },            
                    {
                        pageFlag: true,
                        id: "TreeTransferTest",
                        title: "生成报价单",
                        name: "TreeTransferTest",
                        icon: "el-icon-document",
                        component: "views/quote/TreeTransferTest.vue",
                        componentdetail: "views/quote/TreeTransferTest.vue",
                        visible: true
                    } ,
                    {
                        pageFlag: true,
                        id: "TableSASaleQuotation",
                        title: "报价单",
                        name: "TableSASaleQuotation",
                        icon: "el-icon-document",
                        component: "views/quote/TableSASaleQuotation.vue",
                        componentdetail: "views/quote/tablesarpt.vue",
                        visible: true
                    },
                    {
                          pageFlag: true,
                          id: "rpt1",
                          title: "生产订单",
                          name: "rpt1",
                          icon: "el-icon-document",
                          component: "views/quote/Rpt1.vue",
                          visible:true
                    },
                    {
                        pageFlag: true,
                        id: "rpt2",
                        title: "工序损耗表",
                        name: "rpt2",
                        icon: "el-icon-document",
                        component: "views/quote/Rpt2.vue",
                        visible:true
                    },
                    // {
                    //     pageFlag: true,
                    //     id: "rpt3",
                    //     title: "标签打印",
                    //     name: "rpt3",
                    //     icon: "el-icon-document",
                    //     component: "views/quote/Rpt3.vue",
                    //     visible:true
                    // },
                    {
                        pageFlag: true,
                        id: "processtransfer",
                        title: "标签打印电镀牌",
                        name: "processtransfer",
                        icon: "el-icon-document",
                        component: "views/quote/processtransfer.vue",
                        visible:true
                    },
                    {
                        pageFlag: true,
                        id: "TableMpQuotation",
                        title: "流程单",
                        name: "TableMpQuotation",
                        icon: "el-icon-document",
                        component: "views/quote/TableMpQuotation.vue",
                        componentdetail: "views/quote/tablesarpt.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_excel_ruku",
                        title: "入库导入",
                        name: "ax_z_excel_ruku",
                        icon: "el-icon-document",
                        component: "views/excel/AxZExcelRukuList.vue",
                        componentdetail: "views/excel/AxZExcelRukuDetail.vue",
                        visible: true
                    }, 
                    {
                        pageFlag: true,
                        id: "ax_imp_iesbom",
                        title: "bom导入生成",
                        name: "ax_imp_iesbom",
                        icon: "el-icon-document",
                        component: "views/quote/impt/AxImpIesbomList.vue",
                        componentdetail: "views/quote/impt/AxImpIesbomDetail.vue",
                        visible: false
                    },
                    {
                        pageFlag: true,
                        id: "ax_impnew_bom",
                        title: "bom导入生成new",
                        name: "ax_impnew_bom",
                        icon: "el-icon-document",
                        component: "views/quote/impt/AxImpnewbomList.vue",
                        componentdetail: "views/quote/impt/AxImpnewbomDetail.vue",
                        visible: false
                    },
                    {
                        pageFlag: true,
                        id: "ax_impnew_delete",
                        title: "bom删除",
                        name: "ax_impnew_delete",
                        icon: "el-icon-document",
                        component: "views/quote/impt/BomDelete.vue",
                        componentdetail: "views/quote/impt/AxImpnewbomDetail.vue",
                        visible: false
                    },
                    {
                        pageFlag: true,
                        id: "ax_z_vou_shouho",
                        title: "确认收货",
                        name: "ax_z_vou_shouho",
                        icon: "el-icon-document",
                        component: "views/zb/vou/AxZVouShouhoList.vue",
                        componentdetail: "views/zb/vou/AxZVouShouhoDetail.vue",
                        visible: true
                    }
                ],
            }, 
            {pageFlag: false,
                id: "sycreport",
                title: "报表",
                icon: "el-icon-menu",
                visible:true,
                children:[
                    {
                        pageFlag: true,
                        id: "repprogress",
                        title: "进度表",
                        name: "repprogress",
                        icon: "el-icon-document",
                        component: "views/zb/report/repprogress.vue",
                        componentdetail: "views/zb/vou/AxZVouShouhoDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "repprogressbmjc",
                        title: "部门结存表",
                        name: "repprogress",
                        icon: "el-icon-document",
                        component: "views/zb/report/repprogressbmjc.vue",
                        componentdetail: "views/zb/vou/AxZVouShouhoDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "repprogressxsb",
                        title: "镶石数量表",
                        name: "repprogressxsb",
                        icon: "el-icon-document",
                        component: "views/zb/report/repprogressxsb.vue",
                        componentdetail: "views/zb/vou/AxZVouShouhoDetail.vue",
                        visible: true
                    },
                    {
                        pageFlag: true,
                        id: "repprogressczb",
                        title: "产值表",
                        name: "repprogressczb",
                        icon: "el-icon-document",
                        component: "views/zb/report/repprogressczb.vue",
                        componentdetail: "views/zb/vou/AxZVouShouhoDetail.vue",
                        visible: true
                    }
                    
                ]
            },
            {pageFlag: false,
                id: "doccreate",
                title: "单据生成",
                icon: "el-icon-menu",
                visible:true,
                children:[
                    {
                        pageFlag: true,
                        id: "ManufactureReportCreat",
                        title: "派工单生成汇报单",
                        name: "ManufactureReportCreat",
                        icon: "el-icon-document",
                        component: "views/zb/doccreate/ManufactureReportCreat.vue"
                        ,visible:true
                      }
                ]
            },
            {pageFlag: false,
                id: "syc",
                title: "数据同步",
                icon: "el-icon-menu",
                children:[
                    {
                        pageFlag: true,
                        id: "inventoryclass",
                        title: "存货分类同步",
                        name: "inventoryclass",
                        icon: "el-icon-document",
                        component: "views/saiz/InventoryClass.vue"
                        ,visible:true
                      },{
                        pageFlag: true,
                        id: "inventory",
                        title: "存货同步",
                        name: "inventory",
                        icon: "el-icon-document",
                        component: "views/quote/tongbu/Inventory.vue"
                        ,visible:true
                      },{
                        pageFlag: true,
                        id: "XHDTOJHD",
                        title: "销货单同步到进货单",
                        name: "XHDTOJHD",
                        icon: "el-icon-document",
                        component: "views/quote/tongbu/XHDTOJHD.vue"
                        ,visible:true
                      }
                ]
            },
            {pageFlag: false,
                id: "log",
                title: "日志",
                icon: "el-icon-menu",
                visible:true,
                children:[
                    {
                        pageFlag: true,
                        id: "ax_z_salecolorchange",
                        title: "销售订单颜色修改日志",
                        name: "ax_z_salecolorchange",
                        icon: "el-icon-document",
                        component: "views/zb/logger/AxZSalecolorchangeList.vue",
                        componentdetail: "views/logger/zb/AxZSalecolorchangeDetail.vue",
                        visible: true
                    }
                ]
            },
        ],
    }, ],
    __mdilist: [],
    set activeid(val) {
        this.__activeid = val;
    },
    get activeid() {
        return this.__activeid;
    },
    set menu(val) {
        this.__menu = val;
    },
    get menu() {
        // console.log(this.__menu);
        return this.__menu;
    },
    set mdilist(val) {
        this.__mdilist = val;
    },
    get mdilist() {
        return this.__mdilist;
    }
}


let ld = (function(__system) {
    let _ld = {
        "system": __system,
        "type":"",
    }
    return _ld;
})(__system)
export default ld